module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

// -*- coding: utf-8 -*-
// (c) 2018 Andreas Motl <andreas.motl@ip-tools.org>

__p+='\n\n<!--\nButton for opening the stack user interface.\nIt also displays the number of selected documents.\nIt is a Material Design Component (MDC) Button with dynamic content.\n -->\n<button class="mdc-button mdc-button--outlined mdc-button--dense ripple">\n    #<span id="count"></span>&nbsp;selected\n</button>\n';
}
return __p;
};
