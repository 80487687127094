module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

// -*- coding: utf-8 -*-
// (c) 2013-2016 Andreas Motl, Elmyra UG

__p+='\n\n<div class="alert alert-block alert-inline '+
((__t=( clazz ))==null?'':__t)+
' user-alert">\n    <button type="button" class="close" data-dismiss="alert">&times;</button>\n    <h4>'+
((__t=( title ))==null?'':__t)+
'</h4>\n    <br/>\n    '+
((__t=( description ))==null?'':__t)+
'\n</div>\n';
}
return __p;
};
