module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

// -*- coding: utf-8 -*-
// (c) 2014 Andreas Motl, Elmyra UG

__p+='\n\n<div class="container-fluid hide area-comment-text">\n    <div class="row-fluid">\n        <div class="span12">\n            <textarea class="span12" rows="8" placeholder="Enter a comment..."></textarea>\n        </div>\n    </div>\n</div>\n';
}
return __p;
};
