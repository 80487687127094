module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

// -*- coding: utf-8 -*-
// (c) 2013-2018 Andreas Motl <andreas.motl@ip-tools.org>

__p+='\n\n<!-- This template is used in regular mode -->\n\n    <div class="pagination-chooser">\n\n        <span class="">\n            <span class="pagination medium">\n                <a href="#" class="first" data-action="first">&laquo;</a>\n                <a href="#" class="previous" data-action="previous">&lsaquo;</a>\n                <input type="text" readonly="readonly" />\n                <a href="#" class="next" data-action="next">&rsaquo;</a>\n                <a href="#" class="last" data-action="last">&raquo;</a>\n            </span>\n        </span>\n\n        <span class="span2">\n            <span class="btn-group btn-popover page-size-chooser"\n                 data-toggle="popover" data-trigger="hover" data-placement="bottom"\n                 data-content="Select page size"\n                    >\n                <button class="btn dropdown-toggle" data-toggle="dropdown">\n                    <i class="icon-resize-vertical"></i> &nbsp; Page size: '+
((__t=( page_size ))==null?'':__t)+
'\n                </button>\n                <button class="btn dropdown-toggle" data-toggle="dropdown">\n                    <span class="caret"></span>\n                </button>\n                <ul class="dropdown-menu">\n                </ul>\n            </span>\n        </span>\n\n    </div>\n';
}
return __p;
};
