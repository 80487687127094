module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

// -*- coding: utf-8 -*-
// (c) 2013,2014 Andreas Motl, Elmyra UG

__p+='\n\n<div class="modal-header">\n    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>\n    <h3 id="keyword-editor-label">Keyword editor</h3>\n</div>\n<div class="modal-body">\n    Edit keywords for individual highlighting\n    <br/><br/>\n    <table class="table table-condensed">\n        <thead>\n            <tr>\n                <th class="span10">Keywords</th>\n                <th class="span2">Style</th>\n            </tr>\n        </thead>\n        <tbody>\n        </tbody>\n    </table>\n</div>\n<div class="modal-footer">\n    <button class="btn" id="keyword-editor-save-button" type="button">Save</button>\n    <button class="btn" data-dismiss="modal" aria-hidden="true">Close</button>\n</div>\n';
}
return __p;
};
