module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

// -*- coding: utf-8 -*-
// (c) 2018 Andreas Motl <andreas.motl@ip-tools.org>

__p+='\n\n<!-- Checkbox for putting documents onto a stack -->\n<label class="hero-checkbox">\n    <span class="text_label"></span>\n    <input type="checkbox">\n    <span class="checkmark"></span>\n</label>\n';
}
return __p;
};
