module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

// -*- coding: utf-8 -*-
// (c) 2013-2017 Andreas Motl, Elmyra UG

__p+='\n\n<div class="row">\n    <div class="span6">\n        <strong>Removed '+
((__t=( Humanize.intComma(data.count_removed) ))==null?'':__t)+
' family members</strong>\n        from results range '+
((__t=( data.start ))==null?'':__t)+
'-'+
((__t=( data.end ))==null?'':__t)+
'.\n        <br/><br/>\n        This gives an estimation of <strong>'+
((__t=( Humanize.intComma(data.count_total_estimated) ))==null?'':__t)+
'</strong> results\n        when grossed up against the total result count and will\n        save on reviewing about <strong>'+
((__t=( Humanize.intComma(data.count_saved_estimated) ))==null?'':__t)+
'</strong> documents.\n    </div>\n    <div class="span5">\n        <div class="pull-right">\n            <button class="btn btn-default btn-large" role="button" data-toggle="collapse" data-target="#family-members-removed">\n                Display '+
((__t=( Humanize.intComma(data.count_removed) ))==null?'':__t)+
' removed family\n                <br/>\n                members between '+
((__t=( data.start ))==null?'':__t)+
'-'+
((__t=( data.end ))==null?'':__t)+
'\n            </button>\n            <br/><br/>\n            <div id="family-members-removed" class="collapse">\n                <textarea rows="20" class="monospace text-small">'+
((__t=( _.pluck(data.family_members_removed, 'publication_number').join('\n') ))==null?'':__t)+
'</textarea>\n            </div>\n        </div>\n    </div>\n</div>\n';
}
return __p;
};
