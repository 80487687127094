module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

// -*- coding: utf-8 -*-
// (c) 2013-2017 Andreas Motl, Elmyra UG

__p+='\n\n';

//log('family member:', data);
var pubref = data.get_publication_reference(null, 'docdb');

__p+='\n\n<td>\n    '+
((__t=( data.get_citations_environment_button() ))==null?'':__t)+
'\n</td>\n\n<td>\n    '+
((__t=( data.enrich_link(pubref.fullnumber, 'pn', null, null, {'no_modifiers': true}) ))==null?'':__t)+
'\n    <br/>\n    '+
((__t=( pubref.isodate ))==null?'':__t)+
'\n</td>\n\n<td>\n    '+
((__t=( data.get_patent_citation_list(true).join(', ') || '&nbsp;' ))==null?'':__t)+
'\n</td>\n';
}
return __p;
};
