module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

// -*- coding: utf-8 -*-
// (c) 2013-2019 Andreas Motl <andreas.motl@ip-tools.org>

__p+='\n\n';


// 1. debugging: log model item to assist in data exploration
//console.log('ops-item:', data);

var meta = data['__meta__'];

// 2.2 prepare some template variables

var patent_number = data.get_patent_number();
var linkmaker = data.get_linkmaker();
var publication_date = data.get_publication_date();
var application_date = data.get_application_date();

var title_list = data.get_title_list();
var abstract_list = data.get_abstract_list();


__p+='\n\n<div class="container-fluid ops-collection-entry page-break-after" data-document-number="'+
((__t=( patent_number ))==null?'':__t)+
'">\n\n    <div class="ops-collection-entry-heading document-anchor">\n\n        <!-- Document header: Left side -->\n        <div class="document-header-main">\n\n            <!-- Document identifier / Patent number -->\n            <div class="document-title">\n                '+
((__t=( data.enrich_link(patent_number, 'pn', null, null, {'no_modifiers': true}) ))==null?'':__t)+
'\n            </div>\n\n            <!-- More bibliographic info -->\n            <div>\n                ';
 if (publication_date) { 
__p+='\n                <div>\n                    Published on: '+
((__t=( data.enrich_link(publication_date, 'publicationdate') ))==null?'':__t)+
'\n                </div>\n                ';
 } 
__p+='\n                ';
 if (meta && meta['swapped'] && meta['swapped']['canonical']) { 
__p+='\n                <div>\n                    First hit: '+
((__t=( data.enrich_link(meta['swapped']['canonical'], 'pn', null, null, {'no_modifiers': true}) ))==null?'':__t)+
'\n                </div>\n                ';
 } 
__p+='\n            </div>\n\n            <!-- Document details: Claims, Description, Family -->\n            <div class="btn-group btn-popover tabs document-details-chooser do-not-print"\n                 data-toggle="popover" data-trigger="hover" data-placement="top"\n                 data-content="Toggle detail view"\n                    >\n                <button class="btn active" data-toggle="tab"\n                        href="#document-details-biblio-container-'+
((__t=( patent_number ))==null?'':__t)+
'">\n                    Biblio\n                </button>\n                ';
 if (data.has_fulltext()) { 
__p+='\n                <button class="btn" data-toggle="tab"\n                        href="#document-details-claims-container-'+
((__t=( patent_number ))==null?'':__t)+
'"\n                        data-details-title="Claims"\n                        data-details-type="claims"\n                        >\n                    Claims\n                </button>\n                <button class="btn" data-toggle="tab"\n                        href="#document-details-description-container-'+
((__t=( patent_number ))==null?'':__t)+
'"\n                        data-details-title="Description"\n                        data-details-type="description"\n                        >\n                    Desc\n                </button>\n                ';
 } 
__p+='\n                <button class="btn" data-toggle="tab"\n                        id="document-details-family-button-'+
((__t=( patent_number ))==null?'':__t)+
'"\n                        href="#document-details-family-container-'+
((__t=( patent_number ))==null?'':__t)+
'"\n                        data-details-type="family"\n                        >\n                    Family\n                </button>\n            </div>\n\n\n            <!-- Actions: Rating, Select for stack -->\n            <div class="document-rating-stack do-not-print">\n\n                ';
 if (navigatorApp.component_enabled('basket')) { 
__p+='\n                    <span class="document-rating-widget">\n                        <a id="remove-patent-number-'+
((__t=( patent_number ))==null?'':__t)+
'" role="button"\n                           class="btn-popover incognito remove-patent-number pointer"\n                           data-patent-number="'+
((__t=( patent_number ))==null?'':__t)+
'"\n                           data-toggle="popover" data-trigger="hover" data-placement="top"\n                           data-content="Remove document number from collection">\n                            <i class="icon-trash icon-large"></i>\n                        </a>\n                        &nbsp;\n                        <span\n                            class="rating-widget"\n                            id="rate-patent-number-'+
((__t=( patent_number ))==null?'':__t)+
'"\n                            data-document-number="'+
((__t=( patent_number ))==null?'':__t)+
'"\n                            style="display: inline-block;">\n                        </span>\n                    </span>\n\n                    <!--\n                    <input type="checkbox" id="chk-patent-number-'+
((__t=( patent_number ))==null?'':__t)+
'" class="chk-patent-number" value="'+
((__t=( patent_number ))==null?'':__t)+
'"/>\n                    <a id="add-patent-number-'+
((__t=( patent_number ))==null?'':__t)+
'" role="button" class="btn btn-popover add-patent-number"\n                       data-patent-number="'+
((__t=( patent_number ))==null?'':__t)+
'"\n                       data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Add document number to collection"\n                       >\n                        <i class="icon-white icon-plus"></i>\n                    </a>\n                    -->\n                ';
 } 
__p+='\n\n                <!-- Stack -->\n                <span id="region-stack-checkbox"/>\n\n            </div>\n\n        </div>\n\n\n        <!-- Document header: Menu on right side -->\n        <span class="btn-group btn-popover do-not-print">\n            <button class="btn btn-link btn-menu dropdown-toggle" data-toggle="dropdown">\n                <i class="fa-icon icon-bars icon-2x"></i>\n            </button>\n\n            <!-- Actions: Comment, PDF, Worldlinks -->\n            <ul class="dropdown-menu pull-right document-header-more-menu" role="menu">\n\n                <!--\n                <div class="dropdown-section-header">Actions</div>\n                -->\n\n                <!-- Open PDF document -->\n                <li>\n                    <a href="'+
((__t=( linkmaker.universal_pdf_url() ))==null?'':__t)+
'" target="patzilla-pdf" class="anchor-pdf">\n                        '+
((__t=( navigatorApp.ui.pdf_icon() ))==null?'':__t)+
'\n                        &nbsp;\n                        Open PDF\n                    </a>\n                </li>\n\n                <!-- Add/edit comment -->\n                <li id="region-comment-button"/>\n\n                <!-- Links to external resources -->\n                <li class="dropdown-submenu">\n\n                    <a tabindex="-1" href="#">\n                        <i class="icon-globe icon-large"></i>\n                        &nbsp;\n                        External sources\n                    </a>\n                    <ul class="dropdown-menu more-links-menu-container">\n\n                        <!-- bibliographic data -->\n                        <li><div style="padding-left: 10px">\n                            <h5><i class="icon-list"></i> &nbsp; Bibliographic data</h5>\n                        </div>\n                        </li>\n                        <li>\n                            <a href="'+
((__t=( linkmaker.espacenet_worldwide_url() ))==null?'':__t)+
'" target="_blank" class="anchor-biblio-espacenet">\n                                [BIBLIO] '+
((__t=( patent_number ))==null?'':__t)+
' @ Espacenet\n                            </a>\n                        </li>\n                        <li>\n                            <a href="'+
((__t=( linkmaker.new_espacenet_worldwide_url() ))==null?'':__t)+
'" target="_blank" class="anchor-biblio-espacenet-new">\n                                [BIBLIO] '+
((__t=( patent_number ))==null?'':__t)+
' @ New Espacenet (BETA)\n                            </a>\n                        </li>\n                        <li>\n                            <a href="'+
((__t=( linkmaker.depatisnet_url() ))==null?'':__t)+
'" target="_blank" class="anchor-biblio-depatisnet">\n                                [BIBLIO] '+
((__t=( patent_number ))==null?'':__t)+
' @ DEPATISnet\n                            </a>\n                        </li>\n                        <li>\n                            <a href="'+
((__t=( linkmaker.google_url() ))==null?'':__t)+
'" target="_blank" class="anchor-biblio-google">\n                                [BIBLIO] '+
((__t=( patent_number ))==null?'':__t)+
' @ Google Patents\n                            </a>\n                        </li>\n\n                        ';
 if (linkmaker.country == 'US') { 
__p+='\n                        <li>\n                            <a href="'+
((__t=( linkmaker.uspto_appft_biblio() ))==null?'':__t)+
'" target="_blank" class="anchor-biblio-uspto-appft">\n                                [BIBLIO] '+
((__t=( patent_number ))==null?'':__t)+
' @ USPTO Application Database\n                            </a>\n                        </li>\n                        <li>\n                            <a href="'+
((__t=( linkmaker.uspto_patft_biblio() ))==null?'':__t)+
'" target="_blank" class="anchor-biblio-uspto-patft">\n                                [BIBLIO] '+
((__t=( patent_number ))==null?'':__t)+
' @ USPTO Patent Database\n                            </a>\n                        </li>\n                        ';
 } 
__p+='\n\n                        ';
 if (linkmaker.country == 'WO') { 
__p+='\n                        <li>\n                            <a href="'+
((__t=( linkmaker.wipo_biblio() ))==null?'':__t)+
'" target="_blank" class="anchor-biblio-wipo">\n                                [BIBLIO] '+
((__t=( patent_number ))==null?'':__t)+
' @ WIPO\n                            </a>\n                        </li>\n                        ';
 } 
__p+='\n\n                        ';
 if (linkmaker.country == 'CA') { 
__p+='\n                        <li>\n                            <a href="'+
((__t=( linkmaker.cipo_biblio() ))==null?'':__t)+
'" target="_blank" class="anchor-biblio-cipo">\n                                [BIBLIO] '+
((__t=( patent_number ))==null?'':__t)+
' @ CIPO\n                            </a>\n                        </li>\n                        ';
 } 
__p+='\n\n                        <li class="divider"/>\n\n\n                        <!-- legal status -->\n                        <li><div style="padding-left: 10px">\n                            <h5>§ &nbsp; Legal information</h5>\n                        </div>\n                        </li>\n                        <li>\n                            <a href="'+
((__t=( linkmaker.inpadoc_legal_url() ))==null?'':__t)+
'" target="_blank">\n                                [LEGAL] '+
((__t=( patent_number ))==null?'':__t)+
' @ INPADOC legal status\n                            </a>\n                        </li>\n                        <li>\n                            <a href="'+
((__t=( linkmaker.epo_register_url() ))==null?'':__t)+
'" target="_blank" class="anchor-register-epo">\n                                [LEGAL] '+
((__t=( patent_number ))==null?'':__t)+
' @ European Patent Register\n                            </a>\n                        </li>\n                        <li>\n                            <a href="'+
((__t=( linkmaker.dpma_register_url() ))==null?'':__t)+
'" target="_blank" class="anchor-register-dpma">\n                                [LEGAL] '+
((__t=( patent_number ))==null?'':__t)+
' @ DPMAregister\n                            </a>\n                        </li>\n                        ';
 if (linkmaker.country == 'US') { 
__p+='\n                        <li>\n                            <a href="'+
((__t=( linkmaker.uspto_pair_url() ))==null?'':__t)+
'" target="_blank">\n                                [LEGAL] USPTO PAIR\n                            </a>\n                        </li>\n                        ';
 } 
__p+='\n                        <li class="divider"/>\n\n\n                        <!-- patent family -->\n                        <li><div style="padding-left: 10px">\n                            <h5><i class="icon-group"></i> &nbsp; Patent family information</h5>\n                        </div>\n                        </li>\n                        <li>\n                            <a href="'+
((__t=( linkmaker.ccd_viewer_url() ))==null?'':__t)+
'" target="_blank" class="anchor-ccd">\n                                [FAMILY] '+
((__t=( patent_number ))==null?'':__t)+
' @ CCD Viewer\n                            </a>\n                        </li>\n                        <li>\n                            <a href="'+
((__t=( linkmaker.global_dossier_application_viewer_url() ))==null?'':__t)+
'" target="_blank">\n                                [FAMILY] '+
((__t=( patent_number ))==null?'':__t)+
' @ USPTO Global Dossier (application)\n                            </a>\n                        </li>\n                        <li>\n                            <a href="'+
((__t=( linkmaker.global_dossier_publication_viewer_url() ))==null?'':__t)+
'" target="_blank">\n                                [FAMILY] '+
((__t=( patent_number ))==null?'':__t)+
' @ USPTO Global Dossier (publication)\n                            </a>\n                        </li>\n                        <li>\n                            <a href="'+
((__t=( linkmaker.global_dossier_patent_viewer_url() ))==null?'':__t)+
'" target="_blank">\n                                [FAMILY] '+
((__t=( patent_number ))==null?'':__t)+
' @ USPTO Global Dossier (patent)\n                            </a>\n                        </li>\n                        <li>\n                            <a href="'+
((__t=( linkmaker.inpadoc_family_url() ))==null?'':__t)+
'" target="_blank">\n                                [FAMILY] '+
((__t=( patent_number ))==null?'':__t)+
' @ INPADOC patent family\n                            </a>\n                        </li>\n                        <!--\n                        <li>\n                            <a href="'+
((__t=( linkmaker.ops_family_url() ))==null?'':__t)+
'" target="_blank">\n                                [FAMILY] '+
((__t=( patent_number ))==null?'':__t)+
' @ OPS biblio,legal\n                            </a>\n                        </li>\n                        -->\n                        <li class="divider"/>\n\n\n                        <!-- PDF -->\n                        <li><div style="padding-left: 10px">\n                            <h5>'+
((__t=( navigatorApp.ui.pdf_icon() ))==null?'':__t)+
' &nbsp; PDF and Images</h5>\n                        </div>\n                        </li>\n                        <li>\n                            <a href="'+
((__t=( linkmaker.ops_pdf_url() ))==null?'':__t)+
'" target="patzilla-pdf" class="anchor-pdf-ops">\n                                [PDF] '+
((__t=( patent_number ))==null?'':__t)+
' @ OPS\n                            </a>\n                        </li>\n                        <li>\n                            <a href="'+
((__t=( linkmaker.espacenet_pdf_url() ))==null?'':__t)+
'" target="patzilla-pdf">\n                                [PDF] '+
((__t=( patent_number ))==null?'':__t)+
' @ Espacenet\n                            </a>\n                        </li>\n                        <li>\n                            <a href="'+
((__t=( linkmaker.depatisnet_pdf_url() ))==null?'':__t)+
'" target="patzilla-pdf">\n                                [PDF] '+
((__t=( patent_number ))==null?'':__t)+
' @ DEPATISnet\n                            </a>\n                        </li>\n\n                        ';
 if (linkmaker.country == 'US') { 
__p+='\n                        <li>\n                            <a href="'+
((__t=( linkmaker.uspto_appft_images() ))==null?'':__t)+
'" target="_blank" class="anchor-images-uspto-appft">\n                                [PDF] '+
((__t=( patent_number ))==null?'':__t)+
' @ USPTO Application Database\n                            </a>\n                        </li>\n                        <li>\n                            <a href="'+
((__t=( linkmaker.uspto_patft_images() ))==null?'':__t)+
'" target="_blank" class="anchor-images-uspto-patft">\n                                [PDF] '+
((__t=( patent_number ))==null?'':__t)+
' @ USPTO Publication Database\n                            </a>\n                        </li>\n                        ';
 } 
__p+='\n\n                    </ul>\n\n                </li>\n                <!--\n                <hr class="divider"/>\n                -->\n\n            </ul>\n        </span>\n\n    </div>\n\n    <span id="region-comment-text"/>\n\n    <div class="ops-collection-entry-inner ops-bibliographic-details">\n\n        <div class="row-fluid">\n            <div class="span12 keyword">\n                <strong>'+
((__t=( title_list.join('<br/>') ))==null?'':__t)+
'</strong>\n            </div>\n        </div>\n\n        <div class="row-fluid">\n            <div class="span5">\n\n                <!-- second header -->\n                <!-- TODO: for letting the drawing follow the text -->\n                <!--\n                <div class="ops-collection-entry-heading-second well span4 hide">second heading</div>\n                -->\n\n                <!-- first drawing only -->\n                <!--\n                <img src="';
 //linkmaker.drawing_url() 
__p+='" alt="No drawing available."/>\n                -->\n\n                <!-- carousel for all drawings -->\n                <div id="drawings-carousel-'+
((__t=( patent_number ))==null?'':__t)+
'" class="carousel slide drawings-carousel">\n                    <!--\n                    <ol class="carousel-indicators">\n                        <li data-target="#drawings-carousel" data-slide-to="0" class="active"></li>\n                        <li data-target="#drawings-carousel" data-slide-to="1"></li>\n                        <li data-target="#drawings-carousel" data-slide-to="2"></li>\n                    </ol>\n                    -->\n                    <!-- carousel items -->\n                    <div class="carousel-inner">\n                        <div class="active item">\n                            <img src="'+
((__t=( linkmaker.drawing_url() ))==null?'':__t)+
'"\n                                 alt="No drawing available."\n                                 onload="var oce_view = $(this).closest(\'.ops-collection-entry\').prop(\'view\'); oce_view && oce_view.signalDrawingLoaded();"/>\n                        </div>\n                    </div>\n                    <!-- carousel navigation -->\n                    <a class="carousel-control left  do-not-print" href="#drawings-carousel-'+
((__t=( patent_number ))==null?'':__t)+
'" data-slide="prev">&lsaquo;</a>\n                    <a class="carousel-control right do-not-print" href="#drawings-carousel-'+
((__t=( patent_number ))==null?'':__t)+
'" data-slide="next">&rsaquo;</a>\n\n                    <a class="carousel-control rotate do-not-print" href="#" data-rotate="right"><i class="fa-icon icon-repeat icon-2x"></i></a>\n\n                </div>\n\n                <div class="drawing-info span12 text-center">\n                    Drawing #<span class="drawing-number">1</span><span class="drawing-totalcount"/>\n                </div>\n\n            </div>\n            <div class="span7 tab-content document-details">\n\n                <div id="document-details-biblio-container-'+
((__t=( patent_number ))==null?'':__t)+
'" data-identifier="'+
((__t=( patent_number ))==null?'':__t)+
'" class="tab-pane fade in active">\n                    <dl class="dl-horizontal dl-horizontal-biblio">\n\n                        <dt class="inid-tooltip" data-toggle="tooltip" title="Applicants">\n                            (71)\n                        </dt>\n                        <dd class="keyword">\n                            '+
((__t=(
                            data.get_applicants(true).map(function(item) {
                                return require('./biblio-party.html')(item);
                            }).join('<br/>') || '&nbsp;'
                            ))==null?'':__t)+
'\n                        </dd>\n\n                        <dt class="inid-tooltip" data-toggle="tooltip" title="Inventors">\n                            (72)\n                        </dt>\n                        <dd class="keyword">\n                            '+
((__t=(
                            data.get_inventors(true).map(function(item) {
                                return require('./biblio-party.html')(item);
                            }).join('<br/>') || '&nbsp;'
                            ))==null?'':__t)+
'\n                        </dd>\n                        <br/>\n\n                        <dt class="inid-tooltip" data-toggle="tooltip" title="Priority application">\n                            (30)\n                        </dt>\n                        <dd class="keyword">\n                            ';
 var priority_claims_rendered = data.get_priority_claims(true).map(function(item) { return '<tr>' + item + '</tr>'; }).join(''); 
__p+='\n                            <table class="table table-striped2 table-condensed">\n                                '+
((__t=( priority_claims_rendered ))==null?'':__t)+
'\n                            </table>\n                        </dd>\n\n                        <dt class="inid-tooltip" data-toggle="tooltip" title="Application / Domestic filing">\n                            (20)\n                        </dt>\n                        <dd class="keyword">\n                            <table class="table table-striped2 table-condensed">\n                                <tr>\n                                    '+
((__t=( data.get_application_references() ))==null?'':__t)+
'\n                                </tr>\n                            </table>\n                        </dd>\n\n                        <br/>\n\n                        <dt class="inid-tooltip" data-toggle="tooltip" title="Classifications">\n                            (51)\n                        </dt>\n                        <dd class="dl-horizontal-inline-container">\n                            <span class="clearfix"></span>\n                            <dl class="dl-horizontal2 dl-horizontal-inline">\n\n                                ';

                                var classifications_map = data.get_classifications(true);
                                var classification_schemes = data.get_classification_schemes();
                                
__p+='\n\n                                ';
 if (classifications_map) { 
__p+='\n\n                                ';

                                _(classification_schemes).each(function(classification_scheme) {
                                
__p+='\n\n                                ';

                                if (!_.isEmpty(classifications_map[classification_scheme])) {
                                
__p+='\n                                <dt>\n                                    '+
((__t=( classification_scheme ))==null?'':__t)+
'\n                                </dt>\n                                <dd class="keyword">\n                                    '+
((__t=( classifications_map[classification_scheme].join(', ') || '&nbsp;' ))==null?'':__t)+
'\n                                </dd>\n                                ';
 } 
__p+='\n\n                                ';
 }); 
__p+='\n\n                                ';
 } else { 
__p+='\n                                No classifications available\n\n                                ';
 } 
__p+='\n\n                            </dl>\n                        </dd>\n\n                        <dt class="inid-tooltip" data-toggle="tooltip" title="References cited">\n                            (56)\n                        </dt>\n                        <dd class="keyword">\n                            ';
 if (data.has_citations()) { 
__p+='\n                            '+
((__t=( data.get_patent_citation_list(true).join(', ') || 'Citations empty' ))==null?'':__t)+
'\n                            ';
 } else { 
__p+='\n                            Citations not available\n                            ';
 } 
__p+='\n                            <br/>\n                            '+
((__t=( data.get_citations_environment_button() ))==null?'':__t)+
'\n                            <a class="btn btn-small family-citations-shortcut-button do-not-print" role="button">\n                                Family » Citations &nbsp; <i class="icon-arrow-right"></i>\n                            </a>\n                        </dd>\n                        <br/>\n\n                        <dt class="inid-tooltip" data-toggle="tooltip" title="Abstract">\n                            (57)\n                        </dt>\n                        <dd class="keyword">\n                            <div class="abstract">\n                                '+
((__t=( abstract_list.join('<br/><br/>') || 'Abstract not available' ))==null?'':__t)+
'\n                            </div>\n                        </dd>\n                        <br/>\n\n                        ';
 if (data.has_full_cycle()) { 
__p+='\n                        <dt class="inid-tooltip" data-toggle="tooltip" title="Full publication cycle">\n                            (F)\n                        </dt>\n                        <dd class="keyword">\n                            <br/>\n                            ';
 _(data.get_full_cycle()).each(function(entry) { 
__p+='\n\n                            '+
((__t=( entry.get_patent_number() ))==null?'':__t)+
',\n                            '+
((__t=( entry.get_publication_date() ))==null?'':__t)+
'\n                            <span class="inid-tooltip strong" data-toggle="tooltip" title="Publication date">(45)</span>\n\n                            &nbsp;&nbsp;\n\n                            <!-- pdf document -->\n                            <a href="'+
((__t=( linkmaker.universal_pdf_url(entry.get_patent_number()) ))==null?'':__t)+
'" target="patzilla-pdf"\n                               class="btn btn-small btn-popover anchor-pdf do-not-print" role="button"\n                               data-toggle="popover" data-trigger="hover" data-placement="top"\n                               data-content="PDF document"\n                                    >\n                                '+
((__t=( navigatorApp.ui.pdf_icon() ))==null?'':__t)+
'\n                            </a>\n\n                            <br/>\n\n                            ';
 }); 
__p+='\n\n                        </dd>\n                        <br/>\n                        ';
 } 
__p+='\n\n                        ';
 var npl_citations_rendered = data.get_npl_citation_list().map(function(item) { return '<tr><td>' + item + '</td></tr>'; }).join('') 
__p+='\n                        ';
 if (npl_citations_rendered) { 
__p+='\n                        <dt class="inid-tooltip" data-toggle="tooltip" title="References to non-patent-literature">\n                            (N)\n                        </dt>\n                        <dd class="keyword">\n                            <table class="table table-striped table-condensed">\n                                '+
((__t=( npl_citations_rendered ))==null?'':__t)+
'\n                            </table>\n                        </dd>\n                        <br/>\n                        ';
 } 
__p+='\n\n                        ';

                        var datasource_label = data.get_datasource_label();
                        if (datasource_label) {
                        
__p+='\n                        <br/><br/><br/>\n                                        <span class="pull-right text-small text-muted">\n                                            Data source: '+
((__t=( datasource_label ))==null?'':__t)+
'\n                                        </span>\n                        <span class="clearfix"></span>\n                        ';
 } 
__p+='\n\n                    </dl>\n                </div>\n\n                <div id="document-details-claims-container-'+
((__t=( patent_number ))==null?'':__t)+
'" data-identifier="'+
((__t=( patent_number ))==null?'':__t)+
'" class="tab-pane fade" style="margin-top: 1em">\n                    <i class="document-details-spinner icon-refresh icon-spin" style="display: none"></i>\n                    <span data-area="fulltext"></span>\n                </div>\n\n                <div id="document-details-description-container-'+
((__t=( patent_number ))==null?'':__t)+
'" data-identifier="'+
((__t=( patent_number ))==null?'':__t)+
'" class="tab-pane fade" style="margin-top: 1em">\n                    <i class="document-details-spinner icon-refresh icon-spin" style="display: none"></i>\n                    <span data-area="fulltext"></span>\n                </div>\n\n                <div id="document-details-family-container-'+
((__t=( patent_number ))==null?'':__t)+
'" data-identifier="'+
((__t=( patent_number ))==null?'':__t)+
'" class="tab-pane fade" style="margin-top: 1em">\n                    <strong>Patent family</strong>\n                    &nbsp;&nbsp;&nbsp;\n                    <div class="btn-group tabs family-chooser">\n                        <button class="btn btn-small active" data-toggle="tab" data-view-type="compact">\n                            Compact\n                        </button>\n                        <button class="btn btn-small" data-toggle="tab" data-view-type="verbose">\n                            Full\n                        </button>\n                        ';
 if (navigatorApp.user_has_module('family-citations')) { 
__p+='\n                        <button class="btn btn-small" data-toggle="tab" data-view-type="citations">\n                            Citations\n                        </button>\n                        ';
 } 
__p+='\n                    </div>\n                    &nbsp;&nbsp;&nbsp;\n                    <i class="document-details-spinner icon-refresh icon-spin" style="display: none"></i>\n                    <br/><br/>\n                    <span class="document-details-content keyword"></span>\n                </div>\n\n                <!-- embed 3rd-party component -->\n                <div class="embed-item" data-embed-url="'+
((__t=( data.config.get('embed-item-url') ))==null?'':__t)+
'" data-document-number="'+
((__t=( patent_number ))==null?'':__t)+
'">\n                </div>\n\n            </div>\n        </div>\n\n    </div>\n\n</div>\n';
}
return __p;
};
