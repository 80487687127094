module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

// -*- coding: utf-8 -*-
// (c) 2013-2017 Andreas Motl, Elmyra UG

__p+='\n\n<table class="table table-condensed span6">\n    <thead>\n    <tr>\n        <th class="span1">PDF</th>\n        <th class="span2">Publication</th>\n        <th class="span2">Date</th>\n    </tr>\n    </thead>\n    <tbody>\n    <tr>\n        ';

        var family_members = data.get_patent_family_member_list('epodoc');
        var family_members_comma = family_members.join(',');
        
__p+='\n        <td>\n        </td>\n        <td colspan="2">\n            <a href="?numberlist='+
((__t=( encodeURIComponent(family_members_comma) ))==null?'':__t)+
'" class="query-link" target="_blank">\n                All family members (#'+
((__t=( family_members.length ))==null?'':__t)+
')\n            </a>\n        </td>\n    </tr>\n    </tbody>\n</table>\n';
}
return __p;
};
