module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

// -*- coding: utf-8 -*-
// (c) 2013-2017 Andreas Motl, Elmyra UG

__p+='\n\n';

//log('family member:', data);
var pubref = data.get_publication_reference(null, 'docdb');
var appref = data.get_application_reference(null, 'docdb');
var linkmaker = new Ipsuite.LinkMaker();

__p+='\n\n<td>\n    <!-- pdf document -->\n    <a href="'+
((__t=( linkmaker.universal_pdf_url(pubref.fullnumber) ))==null?'':__t)+
'" target="patzilla-pdf"\n       class="btn btn-small btn-popover anchor-pdf do-not-print" role="button"\n       data-toggle="popover" data-trigger="hover" data-placement="top"\n       data-content="PDF document">\n        '+
((__t=( navigatorApp.ui.pdf_icon() ))==null?'':__t)+
'\n    </a>\n</td>\n<td>\n    '+
((__t=( data.enrich_link(pubref.fullnumber, 'pn') ))==null?'':__t)+
'\n    <br/>\n    '+
((__t=( pubref.isodate ))==null?'':__t)+
'\n</td>\n<td>\n    '+
((__t=( appref.fullnumber ))==null?'':__t)+
'\n    <br/>\n    '+
((__t=( appref.isodate ))==null?'':__t)+
'\n    ';

    if (data['application-reference']['@is-representative'] == 'YES') {
        print('<br/>');
        print('representative: yes');
    }
    
__p+='\n</td>\n<td>\n    ';

    _(to_list(data['priority-claim'])).each(function(priority) {
        //log('priority:', priority);
        var document_id = data.get_document_id(priority, null, 'docdb');
        print(document_id.fullnumber);
        print('<br/>');
        print(document_id.isodate);
        print('<br/>');
        if (priority['priority-active-indicator'] && priority['priority-active-indicator']['$'] == 'YES') {
            print('active: yes');
            print('<br/>');
        }
    });
    
__p+='\n</td>\n';
}
return __p;
};
