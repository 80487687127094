module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

// -*- coding: utf-8 -*-
// (c) 2013-2017 Andreas Motl, Elmyra UG

__p+='\n\n<div class="row" id="family-members-removed-empty-page">\n    <div class="span6">\n        An empty result page was reached while having "Remove family members" enabled.\n        This indicates some family members have actually been removed from the current\n        result page.\n        <br/><br/>\n        Background: We\'re fetching results from upstream data sources in chunks of\n        '+
((__t=( data.chunksize ))==null?'':__t)+
' items, so the removal of family members leads to gaps\n        at the end of each result chunk.\n    </div>\n    <div class="span5 text-align-right">\n        ';
 if (data.recommended_next_page_local) { 
__p+='\n        You might want to just skip to the next page containing valid result items:\n        <br/><br/>\n        <button id="next-page-with-results-button" data-next-page="'+
((__t=( data.recommended_next_page_local ))==null?'':__t)+
'" class="btn btn-large btn-default pull-right" role="button">\n            Go to result page #'+
((__t=( data.recommended_next_page_local ))==null?'':__t)+
'\n        </button>\n        ';
 } else { 
__p+='\n        <div class="pull-right text-large text-align-right">\n            <strong>The end of the result list was reached.</strong>\n        </div>\n        ';
 } 
__p+='\n    </div>\n</div>\n';
}
return __p;
};
