module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

// -*- coding: utf-8 -*-
// (c) 2013-2017 Andreas Motl, Elmyra UG

__p+='\n\n';

var content_parts = [];
if (original && canonical != original) {
content_parts.push(_.escape(_.escape('original: ' + original)));
}
if (epodoc && canonical != epodoc) {
content_parts.push(_.escape(_.escape('epodoc: ' + epodoc)));
}
var content = content_parts.join('<br/>');

__p+='\n\n'+
((__t=( display ))==null?'':__t)+
'\n\n';
 if (content) { 
__p+='\n&nbsp;\n<a href="#" class="incognito do-not-print" onclick="javascript: $(this).popover({\n        html: true,\n        content: \''+
((__t=( content ))==null?'':__t)+
'\',\n        trigger: \'manual\'}); $(this).popover(\'toggle\'); return false;">...</a>\n';
 } 
__p+='\n';
}
return __p;
};
