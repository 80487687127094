module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

// -*- coding: utf-8 -*-
// (c) 2013-2017 Andreas Motl, Elmyra UG

__p+='\n\n<div class="row">\n    <div class="span6">\n        While having "Remove family members" enabled, no multiple documents are in\n        the same patent family between results range '+
((__t=( data.start ))==null?'':__t)+
'-'+
((__t=( data.end ))==null?'':__t)+
'.\n        <br/><br/>\n        No hits were removed. Please advance to later search results.\n    </div>\n    <div class="span5">\n    </div>\n</div>\n';
}
return __p;
};
