module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

// -*- coding: utf-8 -*-
// (c) 2013-2016 Andreas Motl, Elmyra UG

__p+='\n\n<!-- Menu -->\n<span class="btn-group btn-popover do-not-print">\n\n    <button class="btn btn-link btn-menu dropdown-toggle menu-opener" data-toggle="dropdown">\n        <i class="fa-icon icon-bars icon-3x"></i>\n    </button>\n    <ul class="dropdown-menu">\n\n        <li>\n\n            <!-- Actions -->\n            <div class="dropdown-section-header">Actions</div>\n\n            ';
 if (navigatorApp.component_enabled('keywords')) { 
__p+='\n            <a class="action-keyword-editor">\n                <i class="icon-edit"></i>\n                &nbsp;\n                Edit keywords for highlighting\n            </a>\n            ';
 } 
__p+='\n\n            <a class="action-fullscreen" target="_blank">\n                <i class="icon-fullscreen"></i>\n                &nbsp;\n                Enable fullscreen mode\n            </a>\n\n            <hr class="divider"/>\n\n\n            ';
 if (navigatorApp.component_enabled('storage')) { 
__p+='\n            <!-- Database -->\n            <div class="dropdown-section-header">Database</div>\n\n            <!-- import database -->\n            <a id="data-import-button">\n                <i class="icon-folder-open-alt"></i> &nbsp; Import\n            </a>\n            <input type="file" id="data-import-file" class="hide"/>\n\n            <!-- save/export database -->\n            <a id="data-export-button">\n                <i class="icon-save"></i> &nbsp; Export\n            </a>\n\n            <!-- wipe database -->\n            <a id="database-wipe-button">\n                <i class="icon-trash"></i> &nbsp; Wipe\n            </a>\n\n            <hr class="divider"/>\n            ';
 } 
__p+='\n\n\n            <!-- Online help -->\n            <div class="dropdown-section-header">Online help and feedback</div>\n\n            <a class="action-help-shortcuts" target="_blank">\n                <i class="icon-question-sign"></i>\n                &nbsp;\n                Using hotkey navigation\n            </a>\n\n            ';
 if (navigatorApp.is_datasource_enabled('ificlaims')) { 
__p+='\n            <a class="action-help-ificlaims" target="_blank">\n                <i class="icon-question-sign"></i>\n                &nbsp;\n                Searching with IFI CLAIMS\n            </a>\n            ';
 } 
__p+='\n\n            <a class="report-issue-feature" target="_blank">\n                <i class="icon-thumbs-up-alt"></i>\n                &nbsp;\n                Request feature\n            </a>\n            <a class="report-issue-problem" target="_blank">\n                <i class="icon-thumbs-down-alt"></i>\n                &nbsp;\n                Report problem\n            </a>\n\n\n            <hr class="divider"/>\n            <a href="/logout">\n                <i class="icon-signout"></i>\n                &nbsp;\n                Sign out\n            </a>\n\n        </li>\n\n    </ul>\n\n</span>\n';
}
return __p;
};
