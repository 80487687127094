module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

// -*- coding: utf-8 -*-
// (c) 2018 Andreas Motl <andreas.motl@ip-tools.org>

__p+='\n\n<div class="main-grid">\n\n    <!-- Sidebar left -->\n    <div id="sidebar-left">\n\n        <!-- Query builder and basket -->\n        <div id="querybuilder-basket-area" class="container-fluid do-not-print non-liveview">\n\n            <!-- Query builder -->\n            <div class="row-fluid">\n                <div class="span12" id="querybuilder-region">&nbsp;</div>\n            </div>\n\n            <!-- Project chooser and basket -->\n            <div class="row-fluid">\n\n                <div class="span12">\n                    <div class="row-fluid">\n                        <div class="span12">\n                            <!-- project chooser -->\n                            <div id="project-chooser-area"></div>\n                        </div>\n\n                    </div>\n\n                    <div class="row-fluid">\n                        ';
 if (config.get('ship-mode') != 'single-bibdata') { 
__p+='\n                        <div class="span12" id="basket-region"></div>\n                        ';
 } 
__p+='\n                    </div>\n\n                </div>\n            </div>\n\n        </div>\n\n    </div>\n\n    <!-- Separator -->\n    <div class="gutter gutter-horizontal"></div>\n\n    <!-- Content area -->\n    <div id="main-content" class="container-fluid">\n\n        <!-- pager top -->\n        <div class="pager-area do-not-print" id="ops-pagination-region-top"></div>\n\n        <div id="modal-area"></div>\n\n        <!-- metadata -->\n        <div id="ops-metadata-region"></div>\n\n        <!-- notifications and alerts -->\n        <div id="alert-area"></div>\n        <div id="info-area"></div>\n\n        <!-- results -->\n        <div id="ops-collection-region"></div>\n\n        <!-- pager bottom -->\n        <div class="pager-area do-not-print" id="ops-pagination-region-bottom"></div>\n\n    </div>\n\n</div>\n\n';
}
return __p;
};
