module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

// -*- coding: utf-8 -*-
// (c) 2013-2017 Andreas Motl, Elmyra UG

__p+='\n\n<div class="container-fluid header-container">\n    <div class="row-fluid header-content align-middle">\n        <div class="header-content-left">\n            <span id="menu-region" class="non-liveview"></span>\n            <span class="header-title">'+
((__t=( theme['ui.page.title'] ))==null?'':__t)+
'</span>\n            <span class="environment-badge">'+
((__t=( theme['ui.environment.badge'] ))==null?'':__t)+
'</span>\n            <span>'+
((__t=( theme['ui.spinner'] ))==null?'':__t)+
'</span>\n            <span>'+
((__t=( theme['ui.page.subtitle'] ))==null?'':__t)+
'</span>\n            <div>'+
((__t=( theme['ui.page.statusline'] ))==null?'':__t)+
'</div>\n        </div>\n        <div class="header-content-right">\n            '+
((__t=( theme['ui.productname.rich'] ))==null?'':__t)+
'\n        </div>\n    </div>\n</div>\n';
}
return __p;
};
