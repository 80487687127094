module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

// -*- coding: utf-8 -*-
// (c) 2018 Andreas Motl <andreas.motl@ip-tools.org>

__p+='\n\n<div class="container-fluid nopadding header-container">\n    <div class="row-fluid header-content align-middle">\n        <div class="span4 header-content-left">\n            <span id="menu-region" class="menu-wide non-liveview"></span>\n        </div>\n        <div class="span9 header-content-right">\n            <div class="align-right">\n                <div class="header-content-right-inner">'+
((__t=( theme['ui.productname.rich'] ))==null?'':__t)+
'</div>\n            </div>\n        </div>\n    </div>\n</div>\n';
}
return __p;
};
