module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

// -*- coding: utf-8 -*-
// (c) 2013-2016 Andreas Motl, Elmyra UG

__p+='\n\n<div class="alert alert-error alert-block alert-inline error-panel">\n    <button type="button" class="close" data-dismiss="alert">&times;</button>\n    <h4>ERROR</h4>\n\n    <dl class="dl-horizontal dl-horizontal-biblio2">\n\n        <dt>\n            Description\n        </dt>\n        <dd>\n            <h4>'+
((__t=( error.description ))==null?'':__t)+
'</h4>\n        </dd>\n\n        <br/>\n\n        <dt>\n            Location\n        </dt>\n        <dd>\n            '+
((__t=( error.location ))==null?'':__t)+
'\n        </dd>\n\n        <dt>\n            Name\n        </dt>\n        <dd>\n            '+
((__t=( error.name ))==null?'':__t)+
'\n        </dd>\n\n        ';
 if (error.details) { 
__p+='\n        <dt>\n            Details\n        </dt>\n        <dd>\n            '+
((__t=( error.details ))==null?'':__t)+
'\n        </dd>\n        ';
 } 
__p+='\n\n    </dl>\n\n        <span class="pull-right">\n            <button\n                    class="btn btn-danger report-issue-problem"\n                    data-report-origin="document"\n                    data-report-kind="not-available"\n                    data-report-item="'+
((__t=( error.name ))==null?'':__t)+
' ('+
((__t=( error.location ))==null?'':__t)+
')"\n                    data-report-html=".error-panel"\n                    data-dialog-remark-orig="'+
((__t=( htmlentities(error.description) ))==null?'':__t)+
'"\n                    data-dialog-remark="'+
((__t=(
                        htmlentities(error.description)
                        + '\n' +
                        htmlentities(error.details)
                    ))==null?'':__t)+
'"\n                    >\n                Report problem\n            </button>\n        </span>\n\n    <span class="clearfix"/>\n\n</div>\n';
}
return __p;
};
