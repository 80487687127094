module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

// -*- coding: utf-8 -*-
// (c) 2013,2014 Andreas Motl, Elmyra UG

__p+='\n\n<td>\n    <textarea id="keywords-'+
((__t=( name ))==null?'':__t)+
'" data-name="'+
((__t=( name ))==null?'':__t)+
'" rows="2" class="span6">'+
((__t=( keywords.join(',') ))==null?'':__t)+
'</textarea>\n</td>\n<td>\n    <span class="highlight-base highlight-strong-'+
((__t=( name ))==null?'':__t)+
'"><strong>'+
((__t=( name ))==null?'':__t)+
'</strong></span>\n</td>\n';
}
return __p;
};
