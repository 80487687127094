module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

// -*- coding: utf-8 -*-
// (c) 2013-2017 Andreas Motl, Elmyra UG

__p+='\n\n';

//log('family member:', data);
var pubref = data.get_publication_reference(null, 'docdb');
var linkmaker = new Ipsuite.LinkMaker();

__p+='\n\n<td>\n    <!-- pdf document -->\n    <a href="'+
((__t=( linkmaker.universal_pdf_url(pubref.fullnumber) ))==null?'':__t)+
'" target="patzilla-pdf"\n       class="btn btn-small btn-popover anchor-pdf do-not-print" role="button"\n       data-toggle="popover" data-trigger="hover" data-placement="top"\n       data-content="PDF document"\n            >\n        '+
((__t=( navigatorApp.ui.pdf_icon() ))==null?'':__t)+
'\n    </a>\n</td>\n\n<td>\n    '+
((__t=( data.enrich_link(pubref.fullnumber, 'pn', null, null, {'no_modifiers': true}) ))==null?'':__t)+
'\n</td>\n\n<td>\n    '+
((__t=( pubref.isodate ))==null?'':__t)+
'\n</td>\n';
}
return __p;
};
