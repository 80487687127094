module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

// -*- coding: utf-8 -*-
// (c) 2014-2018 Andreas Motl <andreas.motl@ip-tools.org>
//
// --------------------------
// SIP result item template
// --------------------------

__p+='\n\n<div class="container-fluid ops-collection-entry" data-document-number="'+
((__t=( publication_number ))==null?'':__t)+
'" style="page-break-after: always;">\n\n    <div class="ops-collection-entry-heading row-fluid">\n\n        <!-- publication number -->\n        <div class="span3 container-fluid">\n            <div class="header-compact">\n                '+
((__t=( enrich_link(publication_number, 'pn') ))==null?'':__t)+
'\n            </div>\n        </div>\n\n        <div class="span9 container-fluid">\n            <div class="header-biblio">\n                ';
 if (is_document_missing) { 
__p+='\n                <span class="label label-important label-document-missing pull-right">Document not available</span>\n                ';
 } else { 
__p+='\n                <span class="label label-success label-document-missing pull-right">Document available</span>\n                ';
 } 
__p+='\n            </div>\n        </div>\n\n    </div>\n\n    <div class="ops-collection-entry-inner container-fluid">\n\n        <div class="row-fluid">\n\n            <div class="span12 tab-content document-details">\n\n                ';
 if (is_document_missing) { 
__p+='\n                <div class="alert alert-error">\n                    <strong>Bummer!</strong>\n                    <br/><br/>\n                    It looks like the document with the given publication number "'+
((__t=( publication_number ))==null?'':__t)+
'"\n                    is not available in the main result list from the OPS data source.\n                    <br/>\n                    We suggest to try searching by application number, title or other criteria.\n                    <br/>\n                    However, due to differences in publication number formats, or if the upstream search yields\n                    documents from different publication cycles, this might also be a false alarm.\n                </div>\n                ';
 } else { 
__p+='\n                <div class="alert alert-success">\n                    <strong>Success!</strong>\n                    <br/><br/>\n                    The document with the given publication number "'+
((__t=( publication_number ))==null?'':__t)+
'" or a different\n                    publication cycle is already available in the main result list from the OPS data source.\n                </div>\n                ';
 } 
__p+='\n\n                <table class="table table-condensed">\n                    <thead>\n                    <tr>\n                        <th class="span1">Symbol</th>\n                        <th class="span2">Name</th>\n                        <th class="span9">Value</th>\n                    </tr>\n                    </thead>\n                    <tbody>\n                    <tr>\n                        <td></td>\n                        <td>Title</td>\n                        <td>'+
((__t=( enrich_link(title, 'title') ))==null?'':__t)+
'</td>\n                    </tr>\n                    <tr>\n                        <td>(71)</td>\n                        <td>Applicant</td>\n                        <td>'+
((__t=( enrich_link(applicant, 'applicant') ))==null?'':__t)+
'</td>\n                    </tr>\n                    <tr>\n                        <td>(72)</td>\n                        <td>Inventor</td>\n                        <td>'+
((__t=( enrich_link(inventor, 'inventor') ))==null?'':__t)+
'</td>\n                    </tr>\n                    <tr>\n                        <td></td>\n                        <td>Application number</td>\n                        <td>\n                            ';

                            if (!is_application_number_invalid()) {
                                print(enrich_link(get_linkable_application_number(), 'ap'));
                                print('&nbsp;&nbsp;&nbsp;');
                                print('(upstream value: ' + ApplicationNumber + ')');
                            } else {
                                print(ApplicationNumber);
                            }
                            
__p+='\n                            ';
 if (is_application_number_invalid()) { 
__p+='\n                            <span class="pull-right">\n                                <span class="label label-warning">Warning</span>\n                                This might not be a valid application number if it is in format (n)nnnnnyy.\n                            </span>\n                            ';
 } 
__p+='\n                        </td>\n                    </tr>\n                    <tr>\n                        <td></td>\n                        <td>Publication number</td>\n                        <td>'+
((__t=( enrich_link(publication_number, 'pn') ))==null?'':__t)+
'</td>\n                    </tr>\n                    <tr>\n                        <td></td>\n                        <td>Filing date</td>\n                        <td>'+
((__t=( filed ))==null?'':__t)+
'</td>\n                    </tr>\n                    <tr>\n                        <td></td>\n                        <td>Priority</td>\n                        <td>'+
((__t=( Priority ))==null?'':__t)+
'</td>\n                    </tr>\n                    <tr>\n                        <td>(45)</td>\n                        <td>Publication date</td>\n                        <td>'+
((__t=( published ))==null?'':__t)+
'</td>\n                    </tr>\n                    <tr>\n                        <td></td>\n                        <td>Grant date</td>\n                        <td>\n                            '+
((__t=( granted ))==null?'':__t)+
'\n                            ';
 if (_.string.startsWith(granted, '1899')) { 
__p+='\n                            <span class="pull-right">\n                                <span class="label label-warning">Warning</span>\n                                This date looks invalid.\n                            </span>\n                            ';
 } 
__p+='\n                        </td>\n                    </tr>\n                    <tr>\n                        <td></td>\n                        <td>Legal status</td>\n                        <td>'+
((__t=( legalstatus ))==null?'':__t)+
'</td>\n                    </tr>\n                    <tr>\n                        <td></td>\n                        <td>Abstract</td>\n                        <td>'+
((__t=( abstract ))==null?'':__t)+
'</td>\n                    </tr>\n                    <tr>\n                        <td></td>\n                        <td>Image</td>\n                        <td>'+
((__t=( image ))==null?'':__t)+
'</td>\n                    </tr>\n                    <tr>\n                        <td></td>\n                        <td>Version</td>\n                        <td>'+
((__t=( version ))==null?'':__t)+
'</td>\n                    </tr>\n                    <tr>\n                        <td></td>\n                        <td>Upstream provider</td>\n                        <td>'+
((__t=( upstream_provider ))==null?'':__t)+
'</td>\n                    </tr>\n                    </tbody>\n                </table>\n\n            </div>\n\n        </div>\n\n    </div>\n\n</div>\n';
}
return __p;
};
