module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

// -*- coding: utf-8 -*-
// (c) 2014-2016 Andreas Motl, Elmyra UG

__p+='\n\n<div class="container-fluid nopadding popover-container">\n    <div class="row-fluid">\n        <p id="permalink-popover-intro"></p>\n        <p id="permalink-expiration" class="hide">\n            <small>\n                The link will expire <span id="permalink-expiration-time"/>.\n            </small>\n        </p>\n    </div>\n    <!--\n    <div class="row-fluid">\n        <br/>\n    </div>\n    <div class="row-fluid">\n        <textarea id="permalink-uri-textinput" rows="5" class="uneditable-input"></textarea>\n        <br/>\n        <p><small>Please copy the link above for sharing.</small></p>\n    </div>\n    <div class="row-fluid">\n        <br/>\n    </div>\n    -->\n    <div class="row-fluid">\n        <a id="permalink-open" class="btn btn-small pull-right">\n            <i class="icon icon-external-link"></i>\n            &nbsp;\n            Open link\n        </a>\n        <button id="permalink-copy" class="btn btn-small">\n            <i class="icon icon-copy"></i>\n            &nbsp;\n            Copy link\n        </button>\n    </div>\n    <div class="row-fluid">\n        <div id="permalink-popover-message"></div>\n    </div>\n</div>\n';
}
return __p;
};
