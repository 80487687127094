module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

// -*- coding: utf-8 -*-
// (c) 2013-2017 Andreas Motl, Elmyra UG

__p+='\n\n<div class="container-fluid">\n\n    <!-- query builder and basket -->\n    <div class="row-fluid do-not-print non-liveview" id="querybuilder-basket-area">\n\n        <!-- querybuilder -->\n        <div class="span8" id="querybuilder-region">&nbsp;</div>\n\n        <!-- project chooser and basket -->\n        <div class="span3 pull-right">\n            <div class="row-fluid">\n                <div class="span12">\n                    <!-- project chooser -->\n                    <div id="project-chooser-area"></div>\n                </div>\n\n            </div>\n\n            <div class="row-fluid">\n                ';
 if (config.get('ship-mode') != 'single-bibdata') { 
__p+='\n                <div class="span12" id="basket-region"></div>\n                ';
 } 
__p+='\n            </div>\n\n        </div>\n\n    </div>\n\n    <!-- pager top -->\n    <div class="pager-area do-not-print" id="ops-pagination-region-top"></div>\n\n    <div id="modal-area"></div>\n\n    <!-- metadata -->\n    <div id="ops-metadata-region"></div>\n\n    <!-- notifications and alerts -->\n    <div id="alert-area"></div>\n    <div id="info-area"></div>\n\n    <!-- results -->\n    <div id="ops-collection-region"></div>\n\n    <!-- pager bottom -->\n    <div class="pager-area do-not-print" id="ops-pagination-region-bottom"></div>\n\n</div>\n';
}
return __p;
};
