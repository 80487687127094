module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

// -*- coding: utf-8 -*-
// (c) 2013-2019 Andreas Motl <andreas.motl@ip-tools.org>

__p+='\n\n<span data-area="text">\n<strong>'+
((__t=( title ))==null?'':__t)+
'</strong> '+
((__t=( language ? '[' + language + ']' : '' ))==null?'':__t)+
'\n<br/><br/>\n<span class="keyword">'+
((__t=( content ))==null?'':__t)+
'</span>\n<br/><br/><br/>\n';
 if (datasource) { 
__p+='\n<span class="pull-right text-small text-muted">\n    Data source: <span class="datasource-label">'+
((__t=( datasource ))==null?'':__t)+
'</span>\n</span>\n';
 } 
__p+='\n<span class="clearfix"></span>\n</span>\n';
}
return __p;
};
