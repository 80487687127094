module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

// -*- coding: utf-8 -*-
// (c) 2018 Andreas Motl <andreas.motl@ip-tools.org>

__p+='\n\n<!--\nMenu for selecting actions on the current stack.\nIt also displays the number of selected documents.\nIt is a Material Design Component (MDC) Menu with dynamic content.\n -->\n<div class="mdc-menu mdc-menu-surface stack-menu" tabindex="-1">\n    <ul class="mdc-list mdc-list--two-line" role="menu" aria-hidden="true" aria-orientation="vertical">\n        <li class="mdc-list-item" role="menuitem" data-action="edit">\n            <span class="mdc-list-item__graphic material-icons">comment</span>\n            <span class="mdc-list-item__text">\n                <span class="mdc-list-item__primary-text">Edit</span>\n                <span class="mdc-list-item__secondary-text">Bulk-edit selected documents</span>\n            </span>\n            <span class="mdc-list-item__meta">⎇+E</span>\n        </li>\n        <li class="mdc-list-item" role="menuitem" data-action="share">\n            <span class="mdc-list-item__graphic material-icons">share</span>\n            <span class="mdc-list-item__text">\n                <span class="mdc-list-item__primary-text">Share</span>\n                <span class="mdc-list-item__secondary-text">Share documents with others</span>\n            </span>\n            <span class="mdc-list-item__meta">⎇+S</span>\n        </li>\n        ';
 if (navigatorApp.theme.get('feature.xpexport.enabled')) { 
__p+='\n        <li class="mdc-list-item" role="menuitem" data-action="xpexport">\n            <span class="mdc-list-item__graphic material-icons">forward</span>\n                Transfer to XPAT\n            <span class="mdc-list-item__meta">⎇+X</span>\n        </li>\n        ';
 } 
__p+='\n        <li class="mdc-list-item" role="menuitem" data-action="reset">\n            <span class="mdc-list-item__graphic material-icons">clear_all</span>\n            <span class="mdc-list-item__text">\n                <span class="mdc-list-item__primary-text">Reset</span>\n                <span class="mdc-list-item__secondary-text">Clear list of selections</span>\n            </span>\n            <span class="mdc-list-item__meta">⎇+R</span>\n        </li>\n    </ul>\n</div>\n';
}
return __p;
};
