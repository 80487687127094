module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

// -*- coding: utf-8 -*-
// (c) 2014,2015 Andreas Motl, Elmyra UG
//
// ---------------------------------------------
// Modal dialog for displaying arbitrary results
// ---------------------------------------------

__p+='\n\n<div class="modal-header">\n    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>\n    <h3 id="result-label">Results</h3>\n</div>\n<div class="modal-body">\n    <div id="result-info">\n    </div>\n    <br/>\n    <textarea id="result-content" rows="13" cols="80" style="width: 97%"></textarea>\n\n</div>\n<div class="modal-footer">\n    <a id="result-to-basket-button" role="button" class="btn pull-left">\n        <i class="icon-plus" id="result-to-basket-indicator"></i> &nbsp; Add to document collection\n    </a>\n    <a id="result-to-clipboard-button" role="button" class="btn pull-left">\n        <i class="icon-copy"></i> &nbsp; Copy to clipboard\n    </a>\n    <button class="btn" data-dismiss="modal" aria-hidden="true">Close</button>\n</div>\n';
}
return __p;
};
