module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

// -*- coding: utf-8 -*-
// (c) 2013-2016 Andreas Motl, Elmyra UG

__p+='\n\n<div class="container-fluid footer-container">\n\n    <div class="row-fluid">\n\n        <div class="span12">\n            <hr class="clear-margin"/>\n            <div class="page-footer pull-left">\n                '+
((__t=( theme['ui.vendor.copyright'] ))==null?'':__t)+
'\n            </div>\n            <div class="page-footer pull-right">\n                <small>\n                    <div style="text-align: right">\n                        <span>'+
((__t=( theme['ui.page.footer'] ))==null?'':__t)+
'</span>\n                        <br/>\n                        <span>'+
((__t=( theme['ui.version'] ))==null?'':__t)+
'</span>\n                    </div>\n                </small>\n            </div>\n\n        </div>\n\n    </div>\n\n</div>\n';
}
return __p;
};
