module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

// -*- coding: utf-8 -*-
// (c) 2013-2018 Andreas Motl <andreas.motl@ip-tools.org>

__p+='\n\n';

var patent_number = get_patent_number();
var subject = 'Problem when displaying document ' + patent_number;
var body = 'We experienced an error when displaying the document ' + patent_number + ', which is important to us. Please have a look at this problem.';

__p+='\n\n<div class="container-fluid ops-collection-entry page-break-after" data-document-number="'+
((__t=( patent_number ))==null?'':__t)+
'">\n\n    <div class="ops-collection-entry-heading document-anchor">\n\n        <!-- Document header: Left side -->\n        <div class="document-header-main">\n\n            <!-- Document identifier / Patent number -->\n            <div class="document-title">\n                '+
((__t=( patent_number ))==null?'':__t)+
'\n            </div>\n\n        </div>\n\n    </div>\n\n    <div class="ops-collection-entry-inner">\n        <div class="alert alert-error alert-block">\n            An error occurred when displaying the document \''+
((__t=( patent_number ))==null?'':__t)+
'\', please report this problem to us.\n            <br/>\n            Error message: '+
((__t=( data.error_message ))==null?'':__t)+
'\n            <br/><br/><br/>\n            <button\n                    class="btn btn-danger report-issue-problem pull-right"\n                    data-report-origin="result"\n                    data-report-kind="not-available"\n                    data-report-item="'+
((__t=( patent_number ))==null?'':__t)+
'"\n                    data-dialog-remark="An error occurred when displaying the document \''+
((__t=( patent_number ))==null?'':__t)+
'\': '+
((__t=( data.error_message ))==null?'':__t)+
'."\n                    >\n                Report problem\n            </button>\n            <br/><br/>\n        </div>\n    </div>\n\n</div>\n';
}
return __p;
};
