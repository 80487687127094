module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

// -*- coding: utf-8 -*-
// (c) 2014-2018 Andreas Motl <andreas.motl@ip-tools.org>

__p+='\n\n<a href="#">\n    <!-- Add/edit comment -->\n    <i class="icon-comment-alt icon-large"></i>\n    &nbsp;\n    Add or edit comment\n</a>\n';
}
return __p;
};
