module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

// -*- coding: utf-8 -*-
// (c) 2013-2017 Andreas Motl, Elmyra UG

__p+='\n\n<table class="table table-condensed">\n    <thead>\n    <tr>\n        <th class="span1">PDF</th>\n        <th class="span3">Publication</th>\n        <th class="span3">Application</th>\n        <th class="span2">Priority</th>\n    </tr>\n    </thead>\n    <tbody>\n    </tbody>\n</table>\n';
}
return __p;
};
