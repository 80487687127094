module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

// -*- coding: utf-8 -*-
// (c) 2013-2017 Andreas Motl, Elmyra UG

__p+='\n\n<div id="header-region"></div>\n<div id="content-region"></div>\n<div id="footer-region"></div>\n';
}
return __p;
};
