/**
 *
 * Material Design Icons
 *
 * https://google.github.io/material-design-icons/
 * https://github.com/google/material-design-icons
 *
 * https://www.npmjs.com/package/material-design-icons-iconfont
 * https://github.com/jossef/material-design-icons-iconfont
 *
**/

require('material-design-icons-iconfont/dist/material-design-icons.css');
require('./material-icons.css');
