module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

// -*- coding: utf-8 -*-
// (c) 2013-2017 Andreas Motl, Elmyra UG

__p+='\n\n<div class="btn-group btn-popover do-not-print"\n     data-toggle="popover" data-trigger="hover" data-placement="top"\n     data-content="Explore citation environment"\n        >\n    <button class="btn btn-small dropdown-toggle" data-toggle="dropdown">\n        <i class="icon-search"></i>\n    </button>\n    <button class="btn btn-small dropdown-toggle" data-toggle="dropdown">\n        <span class="caret"></span>\n    </button>\n    <ul class="dropdown-menu">\n        ';
 if (data.has_citations()) { 
__p+='\n        ';

        var citations_list  = data.get_patent_citation_list(false, 'epodoc', options);
        var citations_comma = citations_list.join(',');
        
__p+='\n        <li>\n            <a href="?numberlist='+
((__t=( encodeURIComponent(citations_comma) ))==null?'':__t)+
'" class="query-link" target="_blank">\n                Documents cited: pn in (56)\n            </a>\n        </li>\n        <!--\n        <li>\n            <a href="?numberlist='+
((__t=( encodeURIComponent('ct=' + citations_comma) ))==null?'':__t)+
'" class="query-link" target="_blank">\n                Documents citing same citations: ct in (56)\n            </a>\n        </li>\n        -->\n        <li>\n            <a href="?query='+
((__t=( encodeURIComponent(data.get_same_citations_query(options)) ))==null?'':__t)+
'"\n               class="query-link same-citations-link" data-length="'+
((__t=( citations_list.length ))==null?'':__t)+
'" target="_blank">\n                Documents citing same citations: ct in (56)\n            </a>\n        </li>\n        ';
 } 
__p+='\n\n        ';
 try { 
__p+='\n        <li>\n            <a href="?query='+
((__t=( encodeURIComponent(data.get_citing_query()) ))==null?'':__t)+
'" class="query-link" target="_blank">\n                Documents citing '+
((__t=( data.get_publication_number('epodoc') ))==null?'':__t)+
'\n            </a>\n        </li>\n        ';
 } catch(err) {} 
__p+='\n\n    </ul>\n</div>\n';
}
return __p;
};
