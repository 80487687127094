module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

// -*- coding: utf-8 -*-
// (c) 2013-2016 Andreas Motl, Elmyra UG

__p+='\n\n<div class="alert alert-error alert-block alert-inline error-panel">\n    <button type="button" class="close" data-dismiss="alert">&times;</button>\n    <h4>ERROR</h4>\n\n    <dl class="dl-horizontal dl-horizontal-biblio2">\n\n        <dt>\n            Description\n        </dt>\n        <dd>\n            ';
 if (error.description['headers']['content-type'] == 'text/html') { 
__p+='\n            The upstream data source responded with an error message in HTML:\n            <br/><br/>\n            <iframe id="error-iframe">'+
((__t=( error.description['content'] || '&nbsp;' ))==null?'':__t)+
'</iframe>\n            ';
 } else { 
__p+='\n            <h4>'+
((__t=( error.description['content'] || '&nbsp;' ))==null?'':__t)+
'</h4>\n            ';
 } 
__p+='\n        </dd>\n\n        <br/>\n\n        <dt>\n            Location\n        </dt>\n        <dd>\n            '+
((__t=( error.location || '&nbsp;' ))==null?'':__t)+
'\n        </dd>\n\n        <dt>\n            Name\n        </dt>\n        <dd>\n            '+
((__t=( error.name || '&nbsp;' ))==null?'':__t)+
'\n        </dd>\n\n        <br/>\n\n        <dt>\n            Date\n        </dt>\n        <dd>\n            '+
((__t=( error.description['headers']['date'] || '&nbsp;' ))==null?'':__t)+
'\n        </dd>\n\n        <dt>\n            Status\n        </dt>\n        <dd>\n            '+
((__t=( error.description['status_code'] || '&nbsp;' ))==null?'':__t)+
' '+
((__t=( error.description['reason'] || '&nbsp;' ))==null?'':__t)+
'\n        </dd>\n\n        <dt>\n            URL\n        </dt>\n        <dd>\n            '+
((__t=( error.description['url'] || '&nbsp;' ))==null?'':__t)+
'\n        </dd>\n\n    </dl>\n\n        <span class="pull-right">\n            <button\n                    class="btn btn-danger report-issue-problem"\n                    data-report-origin="document"\n                    data-report-kind="not-available"\n                    data-report-item="'+
((__t=( error.name ))==null?'':__t)+
' ('+
((__t=( error.location ))==null?'':__t)+
')"\n                    data-report-html=".error-panel"\n                    data-dialog-remark="'+
((__t=( htmlentities(error.description.content) ))==null?'':__t)+
'"\n                    >\n                Report problem\n            </button>\n        </span>\n\n    <span class="clearfix"/>\n\n</div>\n';
}
return __p;
};
