module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

// -*- coding: utf-8 -*-
// (c) 2013-2017 Andreas Motl, Elmyra UG

__p+='\n\n<table class="table table-condensed">\n    <thead>\n    <tr>\n        <th class="span1">Explore</th>\n        <th class="span2">Publication</th>\n        <th class="span7">References cited</th>\n    </tr>\n    </thead>\n    <tbody>\n    <tr>\n        <td>'+
((__t=( data.get_citations_environment_button() ))==null?'':__t)+
'</td>\n        <td></td>\n        <td>Citation references from all family members (#'+
((__t=( data.get_patent_citation_list(false, 'epodoc').length ))==null?'':__t)+
')</td>\n    </tr>\n    <tr>\n        <td>'+
((__t=( data.get_citations_environment_button({members_no_us: true}) ))==null?'':__t)+
'</td>\n        <td></td>\n        <td>Citation references from non-US family members</td>\n    </tr>\n    </tbody>\n</table>\n';
}
return __p;
};
