module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

// -*- coding: utf-8 -*-
// (c) 2013-2017 Andreas Motl, Elmyra UG

__p+='\n\n';

var query_display_html = htmlentities(query_display);

__p+='\nSearching for <pre class="inline">'+
((__t=( query_display ))==null?'':__t)+
'</pre> at data source\n<strong>'+
((__t=( datasource || 'n/a' ))==null?'':__t)+
'</strong> did not match any documents.\nPlease try refining search criteria or expression.\n<br/>\nIf you think this is a system failure, don\'t hesitate to report this issue to us.\n<button\n        class="btn btn-warning report-issue-problem pull-right"\n        data-report-origin="result"\n        data-report-kind="not-available"\n        data-report-item="'+
((__t=( query_display_html ))==null?'':__t)+
'"\n        data-dialog-remark="Search did not match any documents at &quot;'+
((__t=( datasource ))==null?'':__t)+
'&quot;.&#13;Criteria:&#13;'+
((__t=( query_display_html ))==null?'':__t)+
'"\n        >\n    Report problem\n</button>\n<div class="clearfix"/>\n';
}
return __p;
};
