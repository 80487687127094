module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

// -*- coding: utf-8 -*-
// (c) 2013-2018 Andreas Motl <andreas.motl@ip-tools.org>

__p+='\n\n<!--\n<div class="row-fluid" id="pagination-info">\n    <div class="span12 pagination-centered">\n        &nbsp;&nbsp;\n        current = <span class="badge badge-info">'+
((__t=( data.result_range ))==null?'':__t)+
'</span>\n    </div>\n</div>\n-->\n\n';

// Apply autonumeric formatting. Example: 16,384
var AutoNumeric = require('autonumeric/dist/autoNumeric.min.js').default;
var result_count = '--';
if ($.isNumeric(data.result_count)) {
    result_count = AutoNumeric.format(data.result_count, {decimalPlacesOverride: 0, digitGroupSeparator: ','});
}

__p+='\n\n<div class="container-fluid ops-collection-meta" id="pagination-info">\n\n    <div class="row-fluid">\n\n        <div class="span8">\n\n            <!-- total result count -->\n            <div class="span3">\n                Total:\n                <br/>\n                <span style="font-size: x-large" id="result-count-total">'+
((__t=( result_count ))==null?'':__t)+
'</span>\n\n                ';
 if (asbool(data.config.get('debug')) && data.searchmode == 'subsearch') { 
__p+='\n                <br/>\n                View:\n                <br/>\n                <span style="font-size: x-large">'+
((__t=( data.result_count_received ))==null?'':__t)+
'</span>\n                ';
 } 
__p+='\n            </div>\n\n            <!-- current display range -->\n            <div class="span3">\n                Range:\n                <br/>\n                <span style="font-size: x-large">'+
((__t=( data.result_range != null ? data.result_range : '--' ))==null?'':__t)+
'</span>\n            </div>\n\n            <!-- current cql query string -->\n            <div class="span6">\n                Query:\n                <br/>\n                    <span style="font-size: small" class="cql-query">\n                        ';

                        // HACK: workaround required, for whatever reason; WTF!?
                        var _query;
                        if (data.query_origin && data.query_origin.nodeName == 'TEXTAREA') {
                            _query = data.query_real;
                        } else {
                            _query = data.query_origin;
                        }
                        if (_query) {
                            var expression = _query.expression;
                            if (_query.filter) {
                                expression += ' AND ' + _query.filter;
                            }
                            print(htmlentities(expression, 'ENT_NOQUOTES'));
                        } else {
                            print('--');
                        }
                        
__p+='\n                    </span>\n                <br/>\n\n                ';

                if (data && data.query_data && data.query_data.sorting) {
                
__p+='\n                Sorting:\n                <br/>\n                        <span style="font-size: small" class="cql-query">\n                        ';

                        var sorting = data.query_data.sorting;
                        var sort_order_human = 'unknown';
                        if (sorting.order == 'asc') {
                            sort_order_human = 'ascending';
                        } else if (sorting.order == 'desc') {
                            sort_order_human = 'descending';
                        }
                        var message = 'field=' + sorting.field + ', order=' + sort_order_human;
                        print(message);
                        
__p+='\n                        </span>\n                ';

                }
                
__p+='\n\n                ';
 if (asbool(data.config.get('debug')) && (data.searchmode == 'subsearch') && data.query_real) { 
__p+='\n                <hr class="no-margin"/>\n                Query OPS:\n                <br/>\n                <span style="font-size: small" class="cql-query">'+
((__t=( data.query_real.expression ))==null?'':__t)+
'</span>\n                ';
 } 
__p+='\n\n            </div>\n\n        </div>\n\n        <div class="span4 do-not-print non-liveview">\n\n            ';
 if (data.searchmode == 'subsearch' && !data.reviewmode && data.datasource_info && data.datasource_info.has_resultview) { 
__p+='\n            <div class="span6">\n\n                <div class="btn-group btn-popover tabs content-chooser"\n                     data-toggle="popover" data-trigger="hover" data-placement="top"\n                     data-content="Toggle result view"\n                        >\n                    <button class="btn active" data-toggle="tab" data-list-type="ops">\n                        View\n                    </button>\n                    <button class="btn" data-toggle="tab" data-list-type="upstream">\n                        Results\n                    </button>\n                </div>\n\n            </div>\n            ';
 } 
__p+='\n\n            ';
 if (false) { 
__p+='\n\n            ';
 if (data.config.get('mode') != 'liveview') { 
__p+='\n            <div class="span6 pull-right">\n\n                ';
 if (navigatorApp.component_enabled('basket')) { 
__p+='\n                <a id="basket-add-all-documents" role="button" class="btn btn-popover"\n                   data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Add all documents of current result page to collection"\n                        >\n                    <i class="icon-white icon-plus"></i> Add visible\n                </a>\n                ';
 } 
__p+='\n\n            </div>\n            ';
 } 
__p+='\n\n            <!-- result list actions -->\n            <!-- PDF export and print view -->\n            <!--\n            <div class="btn-group btn-popover result-actions span5 pull-right"\n                 data-toggle="popover" data-trigger="hover" data-placement="top"\n                 data-content="Export results"\n                    >\n                <button class="btn dropdown-toggle" data-toggle="dropdown">\n                    <i class="icon-download-alt icon-large2"></i> &nbsp; Export\n                </button>\n                <button class="btn dropdown-toggle" data-toggle="dropdown">\n                    <span class="caret"></span>\n                </button>\n                <ul class="dropdown-menu">\n                    <li>\n                        <a href="'+
((__t=( data.get_url_pdf() ))==null?'':__t)+
'" target="_blank">\n                            <img src="/static/img/icons/pdf.svg" class="pdf-svg"/> PDF\n                        </a>\n                        <a href="'+
((__t=( data.get_url_print() ))==null?'':__t)+
'" target="_blank">\n                            <i class="icon-print icon-large"></i> Print\n                        </a>\n                    </li>\n                </ul>\n            </div>\n            -->\n            ';
 } 
__p+='\n\n\n            <!-- Collection menu -->\n            <div class="align-right align-middle">\n\n                <span id="region-stack-opener" class="mdc-menu-surface--anchor" style="margin-right: 1rem"/>\n\n                <span class="btn-group btn-popover do-not-print">\n                    <button class="btn btn-link btn-menu dropdown-toggle" data-toggle="dropdown">\n                        <i class="fa-icon icon-tasks icon-2x"></i>\n                    </button>\n                    <ul class="dropdown-menu pull-right">\n\n                        <!--\n                        <div class="dropdown-section-header">Actions</div>\n                        -->\n                        ';
 if (navigatorApp.component_enabled('stack')) { 
__p+='\n                        <li>\n                            <a class="action-select-documents">\n                                <i class="fa-icon icon-check"></i>\n                                &nbsp;\n                                Select documents\n                            </a>\n                        </li>\n                        <li>\n                            <a class="action-rate-documents">\n                                <i class="fa-icon icon-star"></i>\n                                &nbsp;\n                                Rate documents\n                            </a>\n                        </li>\n                        ';
 } 
__p+='\n                        <li>\n                            <a class="action-expand-comments">\n                                <i class="fa-icon icon-edit"></i>\n                                &nbsp;\n                                Edit tags and comments\n                            </a>\n                        </li>\n                    </ul>\n                </span>\n            </div>\n\n\n        </div>\n\n    </div>\n</div>\n';
}
return __p;
};
