module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

// -*- coding: utf-8 -*-
// (c) 2013,2014 Andreas Motl, Elmyra UG

__p+='\n\n<div class="row-fluid">\n    <div class="span5 strong">\n        '+
((__t=( label ))==null?'':__t)+
'\n    </div>\n    <div class="span4">\n        '+
((__t=( _(fields).first() ))==null?'':__t)+
'\n        <br/>\n        <small>'+
((__t=( _(fields).rest(1).join('<br/>') ))==null?'':__t)+
'</small>\n    </div>\n    <div class="span3">\n        '+
((__t=( more ))==null?'':__t)+
'\n    </div>\n</div>\n';
}
return __p;
};
