module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

// -*- coding: utf-8 -*-
// (c) 2013-2019 Andreas Motl <andreas.motl@ip-tools.org>

__p+='\n\n<div class="btn-group btn-popover tabs do-not-print"\n     data-toggle="popover" data-trigger="hover" data-placement="top"\n     data-content="Choose language"\n        >\n    ';

    var first_item_classes = 'active';
    _(items).each(function(item) {
    
__p+='\n\n    <!-- One button per language -->\n    <button class="btn '+
((__t=( first_item_classes ))==null?'':__t)+
'" data-toggle="tab"\n            href="#document-details-'+
((__t=( item.type ))==null?'':__t)+
'-container-'+
((__t=( identifier ))==null?'':__t)+
'-'+
((__t=( item.language ))==null?'':__t)+
'">\n        '+
((__t=( item.language ))==null?'':__t)+
'\n    </button>\n\n    ';

    first_item_classes = '';
    });
    
__p+='\n\n    ';
 /*
    <!-- More utilities -->
    <button data-toggle="dropdown" role="button" class="btn btn-info dropdown-toggle">
        <i class="icon-wrench"></i>
    </button>
    <ul class="dropdown-menu" data-component="nataraja">

        <li>

            <!-- Nataraja Comparison -->
            <a data-action="comparison-select-left" href="#" id="foobar"
               class="btn-popover" data-toggle="popover" data-trigger="hover" data-placement="bottom"
               data-content="Select this text for comparison.">
                <i class="icon-long-arrow-left"></i> &nbsp; Select as comparison source
            </a>
            <a data-action="comparison-select-right" href="#"
                class="btn-popover" data-toggle="popover" data-trigger="hover" data-placement="bottom"
                data-content="Compare against this text.">
                <i class="icon-long-arrow-right"></i> &nbsp; Select as comparison target
            </a>

            <!--
            <hr class="divider"/>
            -->

        </li>
    </ul>
    */ 
__p+='\n\n</div>\n\n\n<div class="tab-content">\n';

var item_template = require('./fulltext.html');
var first_item_classes = 'in active';
_(items).each(function(item) {

__p+='\n\n<div id="document-details-'+
((__t=( item.type ))==null?'':__t)+
'-container-'+
((__t=( identifier ))==null?'':__t)+
'-'+
((__t=( item.language ))==null?'':__t)+
'"\n     data-identifier="'+
((__t=( identifier ))==null?'':__t)+
'"\n     class="tab-pane fade '+
((__t=( first_item_classes ))==null?'':__t)+
'" style="margin-top: 1em">\n    '+
((__t=( item_template(item) ))==null?'':__t)+
'\n</div>\n\n';

first_item_classes = '';
});

__p+='\n</div>\n';
}
return __p;
};
